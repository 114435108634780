import Index from "views/Index.jsx";
import AppDomains from "views/Domains.jsx";
import Profile from "views/Profile.jsx";
import Maps from "views/Maps.jsx";
import Register from "views/Register.jsx";
import Login from "views/Login.jsx";
import Tables from "views/Tables.jsx";
import Icons from "views/Icons.jsx";
import Tests from "views/Icons.jsx";
import Billing from "views/Icons.jsx";
import Users from "views/Icons.jsx";

// Admin dashboard navigation
var routes = [
  // user menus
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/app",
    role: ["Users", "Admins"]
  },
  {
    path: "/tests",
    name: "Tests",
    icon: "ni ni-atom text-red",
    component: Tests,
    layout: "/app",
    role: ["Users", "Admins"]
  },
  {
    path: "/domains",
    name: "Domains",
    icon: "ni ni-planet text-green",
    component: AppDomains,
    layout: "/app",
    role: ["Users", "Admins"]
  },
  {
    path: "/billing",
    name: "Billing",
    icon: "ni ni-credit-card text-blue",
    component: Billing,
    layout: "/app",
    role: ["Users", "Admins"]
  },
  {
    path: "/user-profile",
    name: "Account",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/app",
    role: ["Users", "Admins"]
  },
  // admin only menus
  {
    path: "/tables",
    name: "Global Users",
    icon: "ni ni-single-02 text-dark",
    component: Users,
    layout: "/app",
    role: ["Admins"]
  },
  {
    path: "/tables",
    name: "Global Domains",
    icon: "ni ni-planet text-dark",
    component: Users,
    layout: "/app",
    role: ["Admins"]
  },
  {
    path: "/tables",
    name: "Global Settings",
    icon: "ni ni-settings text-dark",
    component: Users,
    layout: "/app",
    role: ["Admins"]
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/app",
    role: ["Example"]
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/app",
    role: ["Example"]
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/app",
    role: ["Example"]
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    role: ["SystemRouting"] // not in the menu
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    role: ["SystemRouting"] // not in the menu
  },
];
export default routes;
