/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="fixed-bottom">
            <Row>
              <Col xl="12">
                <div className="m-4 text-light copyright text-right text-muted">
                  <strong>© { (new Date().getFullYear()) } {" "}</strong>
                  <a className="text-light"
                    href="#"
                    target="">
                  <strong>smashem.io</strong>
                  </a>
                </div>
              </Col>
            </Row>
        </footer>
      </>
    );
  }
}

export default Footer;
