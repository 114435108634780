/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer bottom">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              © { (new Date().getFullYear()) }{" "}
              <a
                className="font-weight-bold ml-1"
                href="#"
                rel="noopener noreferrer"
                target=""
              >
                smashem.io
              </a>
            </div>
          </Col>

          {/* <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">

              <NavItem>
                <NavLink
                  href="#"
                  rel="noopener noreferrer"
                  target=""
                >
                  About
                </NavLink>
              </NavItem>

            </Nav>
          </Col> */}
        </Row>
      </footer>
    );
  }
}

export default Footer;
