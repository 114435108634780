import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AppLayout from "layouts/App.jsx";
import AuthLayout from "layouts/Auth.jsx";
import CmsLayout from "layouts/Cms.jsx";

// MobX for state 
import { Provider } from 'mobx-react';

// amplify
import Amplify from "aws-amplify";
import config from "aws-exports";

// appState store
import Store from "helpers/Store";

// setup amplify with config
Amplify.configure(config);

// debug
// window.LOG_LEVEL='DEBUG'

const NoMatch = ({ location }) => (
  <div>
    <h3>No match for <code>{location.pathname}</code></h3>
  </div>
)

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <Switch>
        <Route path="/auth/register" render={props => <AuthLayout {...props} />} />
        <Route path="/auth/login" render={props => <AuthLayout {...props} />} />
        <Redirect path="/auth/*" to="/auth/login" render={props => <AuthLayout {...props} />} />
        <Route path="/app" render={props => <AppLayout {...props} />} />
        {/** TODO: define app routes here */}
        <Route exact path="/" render={props => <CmsLayout {...props} />} />
        <Redirect path="*" to="/" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
