import React from "react";
import { Redirect } from 'react-router-dom';

// MobX for state management
import {inject, observer} from 'mobx-react';

// smashem helpers
import Auth from "helpers/Auth";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      validation: {
        error: {
          email: '',
          password: ''
        }
      },
      authenticated: false,
      backgrounds: ["bg-one"]
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    let error = this.state.validation.error;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case 'email': 
        error.email = 
        validEmailRegex.test(value)
          ? ''
          : 'Email is not valid';
        break;
      case 'password': 
        error.password = 
          value.length < 8
            ? 'Password must be 8 characters long!'
            : '';
        break;
      default:
        break;
    }
    
    this.setState({
      validation: {
        error: error
      },
      [name]: value
    });
  }
  handleSubmit(event) {
    const validateForm = (error) => {
      let valid = true;
      Object.values(error).forEach(
        (val) => val.length > 0 && (valid = false)
      );
      return valid;
    }

    var username = this.state.email;
    var password = this.state.password;
    var error = this.state.validation.error;

    if(validateForm(error)) {
      Auth.signIn(username,password);
    }
  }
  UNSAFE_componentWillMount() {
    Auth.checkAuthState();
  }
  UNSAFE_componentDidUpdate() {
    // checks
  }
  componentDidMount() {
    document.body.classList.add(this.state.backgrounds[Math.floor(Math.random() * this.state.backgrounds.length)]);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.state.backgrounds[Math.floor(Math.random() * this.state.backgrounds.length)]);
  }
  render() {
    return (
      <>
        <Col lg="5" md="7">
          { // signed in successfully
            ( 
              this.props.store.state.auth.authenticated === true
            ) &&
            <Redirect 
            to={{
              pathname: '/app/index'
            }} 
            />
          }
          { // error
            (
              this.props.store.state.auth.error === true
              && this.props.store.state.auth.trace.code !== undefined
            ) &&
            <Alert color="danger">
              {this.props.store.state.auth.trace.message}
            </Alert>
          }
          <Card className="bg-secondary shadow opacity-9">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Sign in</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-3">
              <Form role="form">
                <FormGroup 
                    className={
                      ( // has input, and error on validation
                        this.state.validation.error.email.length > 0 &&
                        this.state.email.length > 0
                      ) ? 
                      ('has-danger mb-3') : 
                      ( // has input, and successful on validation
                        this.state.validation.error.email.length === 0 &&
                        this.state.email.length > 0
                      ) ?
                      ('has-success mb-3') : 
                      // empty
                      "mb-3"
                    }
                  >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder="Email" 
                      type="email" 
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      className={
                        (
                          this.state.validation.error.email.length > 0 &&
                          this.state.email.length > 0
                        ) ?
                        ('is-invalid') : ('is-valid')
                      }
                    />
                    {this.state.validation.error.email.length > 0 &&
                    <FormFeedback 
                      className="p-1"
                    >
                    {this.state.validation.error.email}
                    </FormFeedback>
                    }
                  </InputGroup>
                </FormGroup>
                <FormGroup 
                  className=
                  {
                    ( // has input, and error on validation
                      this.state.validation.error.password.length > 0 &&
                      this.state.password.length > 0
                    ) ? 
                    ('has-danger mb-3') : 
                    ( // has input, and successful on validation
                      this.state.validation.error.password.length === 0 &&
                      this.state.password.length > 0
                    ) ?
                    ('has-success mb-3') : 
                    // empty
                    "mb-3"
                  }
                >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      name="password"
                      placeholder="Password" 
                      type="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      className={
                        this.state.validation.error.password.length  > 0 ? 
                        ('is-invalid') : ('is-valid')
                      }
                    />
                    {this.state.validation.error.password.length > 0 &&
                    <FormFeedback 
                      className="p-1"
                    >
                    {this.state.validation.error.password}
                    </FormFeedback>
                    }
                  </InputGroup>
                </FormGroup>
                <Row className="my-3">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckLogin"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckLogin"
                      >
                        <span className="text-muted">Remember me</span>
                      </label>
                    </div>
                    </Col>
                </Row>
                <div className="text-center">
                  <Button 
                    className="my-4" 
                    color="primary" 
                    type="button"
                    onClick={() => this.handleSubmit()}
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/"
                onClick={e => e.preventDefault()}
              >
                <small><strong>Forgot password?</strong></small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default inject('store')(observer(Login));
