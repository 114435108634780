import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

// MobX for state management
import {inject, observer} from 'mobx-react';

// smashem helpers
import Auth from "helpers/Auth";

class AppNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="#"
            >
            {this.props.brandText}
            </Link>
            
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <Button 
                className="btn-icon btn-3" 
                href="#" 
                onClick={e => Auth.signOut()} 
                color="secondary" 
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-user-run" />
                </span>
                <span className="btn-inner--text">Logout</span>
              </Button>
            </Nav>

          </Container>
        </Navbar>
      </>
    );
  }
}

export default inject('store')(observer(AppNavbar));
