import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Alert,
  UncontrolledAlert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

class Cms extends React.Component {  
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    backgrounds: ["bg-one"]
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  UNSAFE_componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  componentDidMount() {
    document.body.classList.add(this.state.backgrounds[Math.floor(Math.random() * this.state.backgrounds.length)]);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.state.backgrounds[Math.floor(Math.random() * this.state.backgrounds.length)]);
  }
  render() {
    return (
      <>
        <div className="main-content">
          <AuthNavbar />
          {/* Page content */}
          <div className="header py-7 py-lg-8"> {/** bg-gradient-info */}
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <div className="header-body text-center">
                    <Card className="bg-transparent border-0">
                    <CardBody>
                      <div className="mb-2 text-white">
                        <h1 className="display-2 text-white text-center">Generate incredible load, using real browsers, that navigate your site - just like real users do</h1>
                        {/* <p className="lead">
                          smashem.io is a platform, that uses the power of the cloud to help you load test your website or api
                        </p> */}
                        <Button 
                          className="btn-5" 
                          color="success" 
                          href="/auth/register"
                          role="button"
                          size="lg"
                          >
                          Create an Account
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="mt-4 bg-dark shadow border-0">
                    <CardBody>
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/kXce9A5vNQQ" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                </Col>
              </Row>
            </Container>
            {/* <Container>
              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Alert color="info" fade={false}>
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{" "}
                    <span className="alert-inner--text">
                      <strong>Info!</strong> This is a info alert—check it out!
                    </span>
                  </Alert>
                </Col>
              </Row>
            </Container> */}
            {/* <Container>
              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="bg-gradient-white border-1 shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Contact</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          User information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-company"
                                >
                                  Company
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="Company"
                                  id="input-company"
                                  placeholder="Company"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email address
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="Email Address"
                                  type="email"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  First name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="Firstname"
                                  id="input-first-name"
                                  placeholder="First name"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Last name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="Lastname"
                                  id="input-last-name"
                                  placeholder="Last name"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">
                          Contact information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Address
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="Address"
                                  id="input-address"
                                  placeholder="Home Address"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-city"
                                >
                                  City
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="New York"
                                  id="input-city"
                                  placeholder="City"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-country"
                                >
                                  Country
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue="United States"
                                  id="input-country"
                                  placeholder="Country"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-country"
                                >
                                  Postal code
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-postal-code"
                                  placeholder="Postal code"
                                  type="number"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container> */}
          </div>
          <AuthFooter />
        </div>
        
      </>
    );
  }
}

export default Cms;
