// smashem helpers
import Storage from "helpers/Storage";

class Domains_1 {

  constructor() {
    this.entity = 'domains'
  }

  getDomains() {
    let domains = Storage
    .getConfigByEntity(this.entity)
    .then((domains) => {
      return domains
    })

    return domains
  }

  addDomain(domain, domainConfig) {
    Storage
      .getConfigByEntity(this.entity)
      .then((domains) => {
        // add domain
        domains[domain] = domainConfig
        // save domain verification
        Storage.setConfig(this.entity, domains)
        return domains
      })
      .catch((e) => {console.log(e)})
  }
}

var Domains = window.Domains = new Domains_1();

export default Domains;