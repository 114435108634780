// imports
import React from "react";
import uuid from "uuid/v4";

// MobX for state management
import { inject, observer } from 'mobx-react';

// smashem helpers
import Store from "helpers/Store";
import Storage from "helpers/Storage";
import Domains from "helpers/Domains";

// reactstrap components
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Label,
  Progress,
  Table
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";

class DomainsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: '',
      visibleInstructions: null,
      verification: false,
      validation: {
        error: {
          domain: '',
          verification: false
        }
      },
    };

    this.toggleInstructions = this.toggleInstructions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  /**
   * Toggle verification method instructions
   * @param {*} e 
   */
  toggleInstructions(e) {
    this.setState({visibleInstructions: e.target.dataset.ref})
  }
  /**
   * Get verification message
   */
  getVerificationMsg() {
    this.setState(state => ({uuid: uuid()}))
  }
  /**
   * Handle the form change event
   * @param {*} event 
   */
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    //eslint-disable-next-line
    const validDomainRegex = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
    const error = this.state.validation.error;

    switch (name) {
      case 'domain': 
        error.domain = 
        validDomainRegex.test(value)
          ? ''
          : 'Domain is not valid';
        break;
      case 'verification': 
        error.verification = 
          value === false
            ? 'You have to select a verification method'
            : '';
      break;
      default:
        break;
    }
    this.setState({
      validation: {
        error: error
      },
      [name]: value
    });
  }
  /**
   * Handle the form submit
   * @param {*} event 
   */
  handleSubmit(event) {
    const validateForm = (error) => {
      let valid = true;
      Object.values(error).forEach(
        (val) => (val.length > 0 || val === false) && (valid = false)
      );
      return valid;
    }

    var error = this.state.validation.error;

    if(validateForm(error)) {
      let domains = this.props.store.state.app.domains;
      let verification = this.state.verification;
      
      let domainConfig = {
        'verified': false,
        'verification-method': verification,
        'verification-code': this.state.uuid
      }

      // add domain to state
      domains[this.state.domain] = domainConfig;

      console.log(this.state.domains);
      console.log(this.state);
      // this.setState(, console.log(this.state))
      console.log(this.state);
      Store.set({area: "app", key: 'domains', data: domains});

      // save domain verification
      // Domains.addDomain(domain);

    }
  }
  async UNSAFE_componentWillMount(){
    this.getVerificationMsg()

    // get domains
    let domains = await Domains.getDomains()
    // set domains to mobx state
    Store.set({area: "app", key: 'domains', data: domains});
  }
  /** 
   * Get Domains from mobx state
   */
  getDomains() {
    if('domains' in this.props.store.state.app) {
      return Object.entries(this.props.store.state.app.domains).map(([domain, values], key) => {
  			return (
          <tr key={key}>
            <th scope="row">
              {domain}
            </th>
            <td>
              <Badge color="" className="badge-dot mr-4">
                  {values['verification-method']}
              </Badge>
            </td>
            <td>
              <Badge color="" className="badge-dot mr-4">
                  {values['verification-code']}
              </Badge>
            </td>
            <td>
              <Badge color="" className="badge-dot mr-4">
                <i className="bg-warning" />
                  {values['verified']}
              </Badge>
            </td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={e => e.preventDefault()}
                >
                <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu direction="up" className="dropdown-menu-arrow">
                  <DropdownItem
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="ni ni-fat-remove" />
                    <span>Remove</span> 
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="ni ni-check-bold" />
                    <span>Verify</span> 
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
        )
  		})
      
    }
  }
  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Domains heading - add domains */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <h3 className=" mb-0">Domains</h3>
                </CardHeader>
                <CardBody>
                  <Row className="">
                    <Col md="10">
                      <p className="word-wrap">
                        Verify your domain to get started
                      </p>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md="6">
                      <Form>
                        <Label for=""><strong>Domain name</strong></Label>
                        <FormGroup 
                          className={
                            ( // has input, and error on validation
                              this.state.validation.error.domain.length > 0 &&
                              this.state.domain.length > 0
                            ) ? 
                            ('has-danger mb-3') : 
                            ( // has input, and successful on validation
                              this.state.validation.error.domain.length === 0 &&
                              this.state.domain.length > 0
                            ) ?
                            ('has-success mb-3') : 
                            // empty
                            "mb-3"
                          }
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-planet" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="domain"
                              name="domain"
                              placeholder="domain.com"
                              type="domain"
                              value={this.state.domain}
                              onChange={this.handleChange}
                              className={
                                (
                                  this.state.validation.error.domain.length > 0 &&
                                  this.state.domain.length > 0
                                ) ?
                                ('is-invalid') : ('is-valid')
                              }
                            />
                            {this.state.validation.error.domain.length > 0 
                            &&
                            <FormFeedback 
                              className="p-1"
                            >
                            {this.state.validation.error.domain}
                            </FormFeedback>
                            }
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="">
                          <Label for=""><strong>Verification method</strong></Label>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="verify-html"
                              name="verification"
                              type="radio"
                              value="html"
                              data-ref="optionHtml"
                              onChange={this.handleChange}
                              onClick={this.toggleInstructions}
                            />
                            <label className="custom-control-label" htmlFor="verify-html">
                              .html file
                            </label>
                            <Collapse isOpen={this.state.visibleInstructions === 'optionHtml'}>
                              <Card>
                                <CardBody>
                                  <p><strong>HTML</strong></p>
                                  <p>
                                    Create a file called <strong>{this.state.uuid}.html</strong> with the only content
                                    inside being <strong>{this.state.uuid}</strong>
                                  </p>
                                  <p>
                                    Upload the file to the root of your website
                                    Example: <strong>https://{this.state.domain ? this.state.domain:'{your-website}'}/{this.state.uuid}.html</strong>
                                  </p>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="verify-meta-tag"
                              name="verification"
                              type="radio"
                              value="meta-tag"
                              data-ref="optionMeta"
                              onChange={this.handleChange}
                              onClick={this.toggleInstructions}
                            />
                            <label className="custom-control-label" htmlFor="verify-meta-tag">
                              Meta tag
                            </label>
                            <Collapse isOpen={this.state.visibleInstructions === 'optionMeta'}>
                              <Card>
                                <CardBody>
                                  <p><strong>Meta-tag</strong></p>
                                  <p>
                                    Create a meta-tag, reachable on the root of your domain, called: <strong>{this.state.uuid}</strong> with the only content
                                    being <strong>{this.state.uuid}</strong>
                                  </p>
                                  <p>
                                    Example: <strong>{`<meta name="${this.state.uuid}" content="${this.state.uuid}" />`}</strong>
                                  </p>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id="verify-dns"
                              name="verification"
                              type="radio"
                              value="dns"
                              data-ref="optionDns"
                              onChange={this.handleChange}
                              onClick={this.toggleInstructions}
                            />
                            <label className="custom-control-label" htmlFor="verify-dns">
                              DNS txt record
                            </label>
                            <Collapse isOpen={this.state.visibleInstructions === 'optionDns'}>
                              <Card>
                                <CardBody>
                                    <p><strong>DNS txt record</strong></p>
                                    <p>
                                      Create a DNS txt, called: <strong>{this.state.uuid}</strong> with the value
                                      being <strong>{this.state.uuid}</strong>
                                    </p>
                                  </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        </FormGroup>
                        <Button 
                          className="btn-icon btn-3" 
                          color="primary" 
                          type="button"
                          onClick={() => this.handleSubmit()}
                          >
                          <span className="btn-inner--icon">
                            <i className="ni ni-check-bold" />
                          </span>
                          <span className="btn-inner--text">Verify</span>
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                  {/** Devider */}
                  <Row className=""><Col md="12">&nbsp;</Col></Row>
                  {/** Domains table */}
                  {
                    ( 
                      true
                    ) &&
                    <Row className="">
                      <Col>
                        <Table size="sm" className="align-items-center table-flush" responsive hover>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Domain</th>
                              <th scope="col">Method</th>
                              <th scope="col">Code</th>
                              <th scope="col">Status</th>
                              <th scope="col" className="text-right">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.getDomains() }
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  }
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default inject('store')(observer(DomainsView));
