// amplify
import AmplifyAuth from '@aws-amplify/auth';

// smashem helpers
import Store from "helpers/Store";
import Storage from "helpers/Storage";

class Auth_1 {
  // check authentication state of current request
  checkAuthState() {
    AmplifyAuth.currentAuthenticatedUser({
      bypassCache: true
    }).then(response => {
      let group = response.signInUserSession.accessToken.payload["cognito:groups"][0]
      let user = { username: response.username, group: group, ...response.attributes }
      if (user.email_verified && user.username) {
        let data = {
          user: user,
          authenticated: true
        }
        // set data to the store using mobx methods
        Store.set({area: "auth", data: data});
      }
    }).catch(error => {
      let data = {
        error: true,
        trace: error
      }
      // set data to the store using mobx methods
      Store.set({area: "auth", data: data});
    });
  }

  // sign in user
  signIn(username, password) {
    AmplifyAuth.signIn({
      username, // Required, the username
      password, // Required, the password
    }).then(response => {
      let group = response.signInUserSession.accessToken.payload["cognito:groups"][0]
      let user = {username:response.username,group:group,...response.attributes}
      if(user.email_verified && user.username) {
        let data = {
          user, 
          authenticated: true
        }

        /**
         * Check if env exists, if not it will be created
         */
        Storage.checkIfEnvExist()

        // set user attributes 
        // set cognito id (mapping to s3 storage)
        AmplifyAuth.currentAuthenticatedUser()
        .then(user => {
          AmplifyAuth.currentUserInfo()
          .then(info => {
            AmplifyAuth.updateUserAttributes(user, {
              'custom:cognito_identity': info.id
            })
          })
          .catch(error => { 
            // TODO: deal with error
          })
        })
        .catch(error => { 
          // TODO: deal with error
        })

        // set auth data to the store using mobx methods
        Store.set({area: "auth", data: data});
      }
    }).catch(error => {
      let data = {
        error: true,
        trace: error,
        authenticated: false
      }
      // set data to the store using mobx methods
      Store.set({area: "auth", data: data});
    });
  }

  signUp(username, password) {
    AmplifyAuth.signUp({
      username, // Required, the username
      password, // Required, the password
    }).then(response => {
        let user = response.user;
        let data = {
          registered: true,
          user, 
          authenticated: false
        }
        // set data to the store using mobx methods
        Store.set({area: "auth", data: data});

    }).catch(error => {
      let data = {
        error: true,
        trace: error,
        authenticated: false
      }
      // set data to the store using mobx methods
      Store.set({area: "auth", data: data});
    });
  }

  // sign out the user
  signOut() {
    // By doing this, you are revoking all the auth tokens(id token, access token and refresh token)
    // which means the user is signed out from all the devices
    // Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
    AmplifyAuth.signOut({ global: true })
      .then(response => {
        let data = {
          authenticated: false
        }
        // set data to the store using mobx methods
        Store.set({area: "auth", data: data});
      })
      .catch(error => {
        let data = {
          error: error,
          authenticated: false
        }
        // set data to the store using mobx methods
        Store.set({area: "auth", data: data});
      });
  }
}

var Auth = window.Auth = new Auth_1();

export default Auth;

