// MobX for state management
import { set, get, autorun, makeObservable, observable, configure } from "mobx"

// configure mobx
configure({
  useProxies: "never",
  enforceActions: "never",
  reactionRequiresObservable: true
  // observableRequiresReaction: true,
})

/**
 * Mobx state store global class
 */
class Store_1 {
  state

  constructor() {
    // setup initial state
    this.state = observable({
      auth: {
        authenticated: false
      },
      app: {
        user: {},
        settings: {},
        domains: {},
        tests: {}
      }
    })
  }
  /**
   * Set data to store
   * @param {*} key
   * @param {*} data
   */
  set({area, key='', data}) {
    console.log(`set ${key} data to ${area}`);
    console.log("data", data);

    if(key) {
      this.state[area][key] = data;
    } else {
      this.state[area] = data;
    }
    
    console.log(this.state);
  }

}

// initialise class as store
var Store = window.Store = new Store_1();

export default Store;