import React from "react";
import { Route, Switch, Redirect} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AppNavbar from "components/Navbars/AppNavbar.jsx";
import AppFooter from "components/Footers/AppFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";

// MobX for state management
import {inject, observer} from 'mobx-react';

class App extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  componentDidMount() {
    // TODO: I'm sure we don't need to check auth below - commenting it out for now
    // SmashemAuth.checkAuthState();
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      // if authenticated in state is false, redirect to login
      if (this.props.store.state.auth.authenticated === false) {
        return (
          <Redirect 
            from="" 
            to="/auth/login"
            key={key}
           />
        );
      }
      // load the app
      if (prop.layout === "/app" 
      ) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      // or if route not found redirect to homepage
      } else {
        return (
          <Redirect 
            from="" 
            to="/"
            key={key}
           />
        );
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/app/index",
            imgSrc: require("assets/img/brand/logo-big.png"),
            imgAlt: "..."
          }}
        /> 
        <div className="main-content" ref="mainContent">
          <AppNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            brandLink={this.props.location}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid>
            <AppFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default inject('store')(observer(App));
