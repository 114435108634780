// amplify
import AmplifyAuth from '@aws-amplify/auth';
import AmplifyStorage from '@aws-amplify/storage';

// smashem helpers
import Store from "helpers/Store";

/**
 * Wrapper for AWS Amplify Storage class providing helper methods
 */
class Storage_1 {

  /**
   * List of available config entities
   */
  getEntities() {
    let entities = [
      'domains',    // domains added by the user
      'logs',       // various logs 
      'settings',   // general account settings
      'tests',      // tests added by the user, with assets
      'credits',    // credits
    ]

    return entities;
  }

  /**
   * create folders (called entities) for our user and app
   */
  createUserEnv() {
    let entities = this.getEntities()

    /**
     * create entities if they don't exist
     * each entity is checked independently
     */
    // eslint-disable-next-line array-callback-return
    entities.map(entity => {
      let configForEntity = this.getConfigByEntity(entity)

      configForEntity
      .then(config => {
        /**
         * if the config entity is false it will be created
         */
        if(false === config) {
          console.log(`config for entity ${entity} does not exist. creating..`)
          this.setConfig(entity)
        }
      })
      .catch(error => {
        // TODO: deal with error
        console.log(error)
      })
    })
  }

  /**
   * Check if environment exists
   */
  checkIfEnvExist() {
    AmplifyAuth.currentUserInfo()
    .then(info => {
      /**
       * Will create environment if it doesn't exist
       */
      this.createUserEnv()
    })
    .catch(error => {
      // TODO: deal with error
      console.log(error)
    })
  }

  /**
   * Get config for the specified entity
   * @param {Type of config entity} entity 
   */
  getConfigByEntity(entity, level='private') {
    let configFile = `${entity}/${entity}-conf.json`;

    /**
     * check if config exists by doing a list
     * we do this becuase .get does not give us the 
     * info needed to figure out if a config exists or not
     * see: https://github.com/aws-amplify/amplify-js/issues/1135
     * and: https://github.com/aws-amplify/amplify-js/issues/1145
     */
    return AmplifyStorage.list(configFile, {level: level})
    .then(response => {
      if(response.length !== 0) {
        /**
         * The config property download: true MUST be set
         * for json.parse to be able to read the file
         * and return the config value
         */
        return AmplifyStorage.get(configFile, {level: level, download: true})
        .then(response => {
          try {
            var config = JSON.parse(response.Body.toString())

            return config
          } catch (error) {
            // TODO: deal with error
            console.log(error)

            return false
          }
        })
        .catch(error => {
          // TODO: deal with error
          console.log(error)

          return false
        })
      } else {
        // config file does not exist
        return false
      }
    })
    .catch(error => {
      // TODO: deal with error
      console.log(error)
    })
  }

  /**
   * Get config for all entities
   */
  getConfig() {
    let entities = this.getEntities()
    let appConfig = {}
    /**
     * Get config for each entity, and set to global state
     */
    // eslint-disable-next-line array-callback-return
    entities.map(entity => {
      let configForEntity = this.getConfigByEntity(entity)

      configForEntity
      .then(config => {
        try {
          appConfig[entity] = config
        } catch (error) {
          // TODO: deal with error 
          console.log(error)
        }
      })
      .catch(error => {
        // TODO: deal with error
        console.log(error)
      })
    })

    return appConfig;
  }

  /**
   * Set config for the specified entity
   * If dataObject is not specified empty config will be set - be careful with this
   * @param {Type of config entity} entity 
   * @param {Data Object} dataObject 
   */
  setConfig(entity, dataObject={}, level='private') {
    let data = {}
    data[entity] = dataObject;
    // set to mobx store
    Store.state.app = data;
    
    // save data to s3
    let configFile = `${entity}/${entity}-conf.json`;
    console.log('setConfig');
    console.log(dataObject)

    return AmplifyStorage.put(
      configFile,
      JSON.stringify(dataObject),
      {level: level, contentType: 'application/json'}
    )
    .then(response => {
      console.log(response)
    })
    .catch(error => {
      console.log(error)
    })
  }
}

var Storage = window.Storage = new Storage_1();

export default Storage;