import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

class AppNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-dark bg-default"
          expand="lg"
          style={{ zIndex: 1090 }}
        >
          <Container>
            <NavbarBrand href="/">
              <img alt="smashem.io" src={require("assets/img/brand/logo-big.png")} />
            </NavbarBrand>
            <button
              aria-controls="navbar-info"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-info"
              data-toggle="collapse"
              id="navbar-info"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse navbar toggler="#navbar-info">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img alt="smashem.io" src={require("assets/img/brand/logo-big.png")} />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-info"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-info"
                      data-toggle="collapse"
                      id="navbar-info"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="/auth/register"
                  >
                    <i className="fas fa-user-plus" />
                    <span className="nav-link-inner--text">Register</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="/auth/login"
                  >
                    <i className="fas fa-sign-out-alt" />
                    <span className="nav-link-inner--text">Login</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>

        {/* <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        >
          <Container className="px-0">
            <NavbarBrand className="bg-dark p-1" to="/" tag={Link}>
              <img alt="smashem.io" src={require("assets/img/brand/logo-big.png")} />
            </NavbarBrand>
            <Nav className="ml-auto navbar-expand-sm" navbar>
                <NavItem className="mb-1 float-sm-left w-50">
                  <Button 
                    className=""
                    color="secondary" 
                    href="/auth/register"
                    role="button">
                    <span className="btn-inner--icon">
                      <i className="ni ni-circle-08" />
                    </span>
                    <span className="btn-inner--text">Register</span>
                  </Button>
                </NavItem>
                <NavItem className="mb-1 float-sm-left w-50">
                  <Button 
                    className="" 
                    color="secondary" 
                    href="/auth/login"
                    role="button">
                    <span className="btn-inner--icon">
                      <i className="ni ni-key-25" />
                    </span>
                    <span className="btn-inner--text">Login</span>
                  </Button>
                </NavItem>
              </Nav>
            <UncontrolledCollapse className="align-right fixed-top" navbar toggler="#navbar-collapse-main">
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="smashem.io"
                        src={require("assets/img/brand/logo-big.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      id="navbar-collapse-main">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              
            </UncontrolledCollapse>
          </Container>
        </Navbar> */}
      </>
    );
  }
}

export default AppNavbar;
