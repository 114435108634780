import React from "react";
// MobX for state management
import {inject, observer} from 'mobx-react';
// smashem helpers
import Auth from "helpers/Auth";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Link,
  Col,
  Row
} from "reactstrap";

/**
 * Register class
 */
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validation: {
        error: {
          email: '',
          password: ''
        }
      },
      backgrounds: ["bg-one"]
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
   * Send back slightly prettier error messages
   * @param {*} error 
   */
  getPrettyError(error) {
    if(error === 'SignUp is not permitted for this user pool') {
      error = 'Registration is temporarily disabled';
    }
    return error;
  }
  /**
   * Handle change event
   * @param {*} event 
   */
  handleChange(event) {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    let error = this.state.validation.error;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case 'email': 
        error.email = 
        validEmailRegex.test(value)
          ? ''
          : 'Email is not valid';
        break;
      case 'password': 
        error.password = 
          value.length < 8
            ? 'Password must be 8 characters long!'
            : '';
        break;
      default:
        break;
    }

    this.setState({
      validation: {
        error: error
      },
      [name]: value
    });
  }
  /**
   * Handle submit event
   * @param {*} event 
   */
  handleSubmit(event) {
    const validateForm = (error) => {
      let valid = true;
      Object.values(error).forEach(
        (val) => val.length > 0 && (valid = false)
      );

      return valid;
    }

    var username = this.state.email;
    var password = this.state.password;
    var error = this.state.validation.error;

    if(validateForm(error)) {
      // kick off signup call
      Auth.signUp(username,password)
    }
  }
  UNSAFE_componentWillMount() {
    document.body.classList.add(this.state.backgrounds[Math.floor(Math.random() * this.state.backgrounds.length)]);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.state.backgrounds[Math.floor(Math.random() * this.state.backgrounds.length)]);
  }
  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Alert className="alert-warning">
            <strong>Registration is temporarily disabled</strong>
          </Alert>
          { // error
            (
              this.props.store.state.auth.error === true
              && this.props.store.state.auth.trace !== "undefined"
              && this.props.store.state.auth.trace  !== "not authenticated"
            ) &&
            <Alert color="danger">
              {this.getPrettyError(this.props.store.state.auth.trace.message)}
            </Alert>
          }
          { // success
            ( 
              this.props.store.state.auth.registered === true
            ) &&
            <Alert color="success">
              Account created successfully. Please click on the link within the verification email,
              sent to <strong>{this.props.store.state.auth.user.username}</strong>
            </Alert>
          }
          <Card className="bg-secondary shadow opacity-9">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Sign up</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-3">
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup 
                  className={
                    ( // has input, and error on validation
                      this.state.validation.error.email.length > 0 &&
                      this.state.email.length > 0
                    ) ? 
                    ('has-danger mb-3') : 
                    ( // has input, and successful on validation
                      this.state.validation.error.email.length === 0 &&
                      this.state.email.length > 0
                    ) ?
                    ('has-success mb-3') : 
                    // empty
                    "mb-3"
                  }
                >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder='Email'
                      className={
                        (
                          this.state.validation.error.email.length > 0 &&
                          this.state.email.length > 0
                        ) ?
                        ('is-invalid') : ('is-valid')
                      }
                      
                    />
                    {this.state.validation.error.email.length > 0 &&
                    <FormFeedback 
                      className="p-1"
                    >
                    {this.state.validation.error.email}
                    </FormFeedback>
                    }
                  </InputGroup>
                </FormGroup>
                <FormGroup 
                  className=
                  {
                    ( // has input, and error on validation
                      this.state.validation.error.password.length > 0 &&
                      this.state.password.length > 0
                    ) ? 
                    ('has-danger mb-3') : 
                    ( // has input, and successful on validation
                      this.state.validation.error.password.length === 0 &&
                      this.state.password.length > 0
                    ) ?
                    ('has-success mb-3') : 
                    // empty
                    "mb-3"
                  }
                >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder='Password'
                      className={
                        this.state.validation.error.password.length  > 0 ? 
                        ('is-invalid') : ('is-valid')
                      }
                    />
                    {this.state.validation.error.password.length > 0 &&
                    <FormFeedback 
                      className="p-1"
                    >
                    {this.state.validation.error.password}
                    </FormFeedback>
                    }
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button 
                    // disabled
                    className="my-4" 
                    color="primary" 
                    type="button"
                    onClick={() => this.handleSubmit()}
                  >
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default inject('store')(observer(Register));


