/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:ddafd4d3-cafc-4b6a-a85a-655b9ca4c9ae",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_50DyNxnlW",
    "aws_user_pools_web_client_id": "1mjlp1euh7mib1kots56oai0b",
    "oauth": {},
    "aws_user_files_s3_bucket": "smashem-userstorage-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://2jsp3w6ayfaw5mu5e3mfl52d4u.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
